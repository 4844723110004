import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { isEmpty } from 'lodash';
import { getOgImage, isBrowser } from "../../utils/functions";
import { useForm } from "react-hook-form"
import { useLazyQuery } from "@apollo/client";
import GET_SEARCH from "../../queries/get-search";
import Spin from "../../components/spin/Spin";
import { Link } from "gatsby";
import './style.scss'

const OpinionPage = (props) => {

  const {
    pageContext: {
      title, seo, uri, content, acfSearch
    }
  } = props;

  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2);
  const {
    buttonText,
    errorFieldRequired,
    headingForSearchResult,
    headingPageOfResult,
    headingProductOfResult,
    headingTypePostOfResult,
  } = acfSearch

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(false)
  const [seachString, setSeachString] = useState('')
  const [results, setResults] = useState({})
  const [resultsCount, setResultsCount] = useState(0)
  const page = "search";

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm()


  useEffect(() => {
    if (isBrowser()) {
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('search');
      if (myParam) {
        setSearch(false);
        setSeachString(myParam);
        setLoading(true);
        getSearch();
        setValue("search", myParam);
      }
    }
  }, [])


  const onSubmit = (values, e) => {
    if (loading) return;
    setSearch(false);
    setSeachString(values.search);
    setLoading(true);
    getSearch();
  }

  const [getSearch, { error, data, getCartRefetch }] = useLazyQuery(GET_SEARCH, {
    variables: {
      search: seachString
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: () => {
      let tmp = 0;
      tmp += data.pages?.nodes.length;
      tmp += data.posts?.nodes.length;
      tmp += data.products?.nodes.length;

      setLoading(false);
      setSearch(true);
      setResultsCount(tmp)
      setResults(data)
    }
  });

  return (
    <Layout lang={lang} page={page} translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={title}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={page}
              openGraphImage={getOgImage(seo)}
            />
            <div className="blank page">
              <section className="description">
                <div className="container container--small">
                  <div className="page__hero">
                    <h1 className="heading heading--xl">
                      {title}
                    </h1>
                  </div>
                  {content &&
                    <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
                  }
                </div>
              </section>
              <section className="search">
                <div className="container container--small">
                  <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form__input">
                      <label className="form__label" htmlFor="search">
                        {buttonText}
                        <span className="form__label__required">*</span>
                      </label>
                      <input
                        id="search"
                        type="text"
                        {...register("search", {
                          required: true,
                        })}
                      />
                      <span className="form__error">{errors.search && errorFieldRequired}</span>
                    </div>
                    <div className="loginPage__button form__button loginPage__register__form__send">
                      <button type="submit">{buttonText}</button>
                      {loading ? <Spin /> : ""}
                    </div>
                  </form>
                </div>
              </section>
              {search &&
                <section className="searchResults">
                  <div className="container container--small">
                    {headingForSearchResult && <h2>{headingForSearchResult}{' '}{resultsCount}</h2>}
                    {results.pages?.nodes.length ?
                      <div className="searchResults__section">
                        <h3>
                          {headingPageOfResult}
                        </h3>
                        <div className="searchResults__items">
                          {results.pages?.nodes.map(item => (
                            <p key={item.id}>
                              <Link to={item.uri}>
                                {item.title}
                              </Link>
                            </p>
                          ))}
                        </div>
                      </div>
                      : ''}
                    {results.posts?.nodes.length ?
                      <div className="searchResults__section">
                        <h3>
                          {headingTypePostOfResult}
                        </h3>
                        <div className="searchResults__items">
                          {results.posts?.nodes.map(item => (
                            <p key={item.id}>
                              <Link to={`${item.uri}`}>
                                {item.title}
                              </Link>
                            </p>
                          ))}
                        </div>
                      </div>
                      : ''}
                    {results.products?.nodes.length ?
                      <div className="searchResults__section">
                        <h3>
                          {headingProductOfResult}
                        </h3>
                        <div className="searchResults__items">
                          {results.products?.nodes.map(item => (
                            <p key={item.id}>
                              <Link to={item.uri}>
                                {item.name}
                              </Link>
                            </p>
                          ))}
                        </div>
                      </div>
                      : ''}
                  </div>

                </section>
              }
            </div>
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  );
};

export default OpinionPage;
